.btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    text-transform: uppercase;
    background-color: var(--color-primary);
    width: 60%;
    padding: space(2) 0;
    color: var(--color-light);
    font-weight: bold;
    border-radius: 8px;
}

.button:disabled {
    opacity: 0.1;
    pointer-events: none;
}

.bg-primary .button {
    background-color: var(--color-light);
    color: var(--color-primary);
}

.button.error {
    background-color: var(--color-error);
    color: var(--color-light);
}
.button.success {
    background-color: var(--color-success);
}
.btn {
    background-color: var(--color-primary);
    color: var(--color-light);
    border-radius: 34px;
    text-align: center;
    padding: 12px space(3);
    position: relative;
    white-space: nowrap;

    &:disabled {
        color: var(--color-primary);
    }

    .spinner-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .three-quarter-spinner {
        width: 1rem;
        height: 1rem;
    }
}
