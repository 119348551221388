.scanner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .scanner {
        width: 100%;
    }

    .overlay {
        background-color: rgba(var(--rgb-primary), 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
