// Variables
// ==================
:root {
    --color-primary: #2b6fb4;
    --color-secondary: #ffd004;
    --color-light: #ffffff;
    --color-dark: #000000;
    --color-grey: #c4c4c4;

    --color-success: #0a7041;
    --color-error: #d90404;

    --rgb-primary: 43, 111, 180;

    --font: 'Arial', sans-serif;
    --space: 8px;
    --container-padding: 32px;
    --container-width: calc(100vw - var(--container-padding) * 2);
    --max-width: 500px;
    --vh100: calc(var(--vh, 1vh) * 100);
}

// Tools
// ==================
@import './tools/reset';
@import './tools/mixins';
@import './tools/typography';
@import './tools/layout';

// Generic
// ==================
html,
body,
#root,
main {
    height: 100%;
}

body {
    color: var(--color-dark);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    padding-bottom: 57px;
}

main {
    max-height: 100%;
    overflow-y: scroll;
}

// Components
// ==================
@import '../components/navigation/navigation.scss';
@import '../components/header/header.scss';
@import '../components/button/button.scss';
@import '../components/scanner/scanner.scss';
@import '../components/notification/notification.scss';
@import '../components/icons/border-icon.scss';
@import '../components/submitting/submitting.scss';
@import '../components/loader/loader.scss';
@import '../components/versionNumber/version-number.scss';

// Modules
// ==================
@import './modules/form.scss';

// Pages
// ==================
@import '../pages/rides/rides.scss';
