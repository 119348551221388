.form-group {
    label {
        font-weight: 600;
    }
    .form-control {
        width: 100%;
        background-color: transparent;
        color: var(--color-dark);
        border-radius: 0;
        border: 1px solid var(--color-primary);
        padding: space(1) space(2);
        border-radius: 8px;

        &.error {
            border: 1px solid var(--color-error);
        }
    }

    .text-error {
        color: var(--color-error);
    }

    .form-control:focus {
        outline: none;
    }

    .input-icon {
        position: relative;
        overflow: hidden;

        .icon {
            position: absolute;
            display: flex;
            align-items: center;
            right: 4px;
            top: 4px;
            bottom: 4px;
            padding: 0 space(1);
            background-color: var(--color-light);
        }

        svg {
            font-size: 1rem;
            color: var(--color-dark);
        }

        &:focus-within {
            svg {
                color: var(--color-dark);
            }
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    .input-checkbox {
        display: flex;
        align-items: center;

        label,
        p {
            margin-left: space(2);
            font-weight: normal;
            font-family: var(--font);
            text-transform: capitalize;
        }
    }

    &.error {
        label {
            color: var(--color-error);
        }
        .form-control {
            border: 1px solid var(--color-error);
        }
        .form-control:focus {
            border: 1px solid var(--color-error);
        }
    }
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    border-radius: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-warning);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    border-radius: 50%;
    background-color: var(--color-light);
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
}

input:checked + .slider {
    background-color: var(--color-success);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--color-success);
}

input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}
