.rides {
    margin-top: 58px;
    padding: space(4) 0;
    display: grid;
    gap: space(6);
}

.ride {
    border: 1px solid var(--color-primary);
    border-radius: 8px;
    overflow: hidden;
    margin-top: space(2);
}

.ride.ride--is-finished {
    border: 1px solid var(--color-grey);
}

.ride--is-finished .ride__header {
    filter: grayscale(0.8);
}

.ride__header {
    background-color: var(--color-primary);
    padding: space(2);
    color: var(--color-light);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ride__title {
    font-weight: 600;
}

.ride__phone-number {
    width: 100%;
}

.ride__content {
    padding: space(2);
    display: grid;
    gap: space(2);
}

.ride__pickup-hour {
    font-size: 1.25rem;
}

.ride__actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: space(2);

    button {
        width: 100%;
    }
}
