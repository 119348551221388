.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-primary);
    color: var(--color-light);
    padding-top: space(2);
    padding-bottom: space(2);
    z-index: 1;
}

.header__title {
    text-align: center;
    font-weight: 600;
}
