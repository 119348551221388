.nav {
    position: fixed;
    z-index: 10;
    bottom: 0;
    width: 100%;
    background-color: var(--color-primary);

    &::before,
    &::after {
        content: '';
        position: absolute;
        background-color: transparent;
        top: -16px;
        height: 16px;
        width: 8px;
        box-shadow: 0 8px 0 0 var(--color-primary);
    }

    &::before {
        border-bottom-left-radius: 8px;
    }

    &::after {
        right: 0;
        border-bottom-right-radius: 8px;
    }
}

.nav ul {
    padding-top: 0.25rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.nav__link {
    color: var(--color-light);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.5rem;
    font-weight: 600;
    padding: space(1) space(2);
    width: 100%;

    svg {
        font-size: 1.25rem;
        margin-bottom: 0.25rem;
    }
}
