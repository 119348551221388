.text-center {
    text-align: center;
}
.text-error {
    color: var(--color-error);
}
.text-success {
    color: var(--color-success);
}
.text-bold {
    font-weight: 600;
}
.text-uppercase {
    text-transform: uppercase;
}
