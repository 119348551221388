.submitting {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--rgb-primary), 0.8);

    display: flex;
    align-items: center;
    justify-content: center;
}
