.relative {
    position: relative;
}

.absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.rounded {
    border-radius: var(--border-radius);
    overflow: hidden;
}

.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    height: 50%;
}

// Color
// ==============
.bg-primary {
    background-color: var(--color-primary);
}

// Container
// ==============
.container {
    width: 100%;
    max-width: var(--max-width);
    margin-right: auto;
    margin-left: auto;
    padding-left: calc(50% - var(--container-width) / 2);
    padding-right: calc(50% - var(--container-width) / 2);
}

// Flex
// ==================
.flex {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-1 {
    flex: 1;
}

// Grid
// ==================
.grid {
    width: 100%;
    display: grid;
    gap: space(2);
}

.grid.grid--small {
    gap: space(1);
}
.grid.grid--big {
    gap: space(5);
}

// Spaces
// =============
@for $i from 0 through 5 {
    body {
        .p-#{$i} {
            padding: space($i);
        }

        .pt-#{$i} {
            padding-top: space($i);
        }

        .pb-#{$i} {
            padding-bottom: space($i);
        }

        .px-#{$i} {
            padding-left: space($i);
            padding-right: space($i);
        }

        .py-#{$i} {
            padding-top: space($i);
            padding-bottom: space($i);
        }

        .my-#{$i} {
            margin-top: space($i);
            margin-bottom: space($i);
        }

        .m-#{$i} {
            margin: space($i);
        }

        .mx-#{$i} {
            margin-left: space($i);
            margin-right: space($i);
        }

        .mt-#{$i} {
            margin-top: space($i);
        }

        .mb-#{$i} {
            margin-bottom: space($i);
        }

        .mr-#{$i} {
            margin-right: space($i);
        }

        .ml-#{$i} {
            margin-left: space($i);
        }
    }
}

.overflowY-hidden {
    overflow-y: hidden;
}
