.notification-wrapper {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: var(--color-primary);
        opacity: 0.8;
    }

    .notification {
        background-color: var(--color-light);
        padding: space(4);
        justify-items: center;
        border: 2px solid var(--color-dark);
        border-radius: 24px;
        opacity: 1;
        animation: fadeIn 0.5s ease;
        animation-fill-mode: forwards;
        text-align: center;

        &.error {
            border-color: var(--color-error);
        }
        &.warning {
            border-color: var(--color-warning);
        }
        &.success {
            border-color: var(--color-success);
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .input-quantity {
        display: flex;
        align-items: center;
        justify-content: center;

        .input {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 space(2);
        }

        input {
            width: 32px;
            text-align: center;
            border: none;
        }
    }
}
