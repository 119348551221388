.border-icon {
    border: 2px solid var(--color-dark);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.error {
        border-color: var(--color-error);
        color: var(--color-error);
    }
    &.warning {
        border-color: var(--color-warning);
        color: var(--color-warning);
    }
    &.success {
        border-color: var(--color-success);
        color: var(--color-success);
    }
}
