@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}

.spinner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.three-quarter-spinner {
    border: 3px solid var(--color-light);
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 0.5s linear 0s infinite;
    width: 5rem;
    height: 5rem;

    &.white {
        border: 3px solid var(--color-light);
        border-top: 3px solid transparent;
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}
